.position_0_0:not(.isMoving) {
	 top: 5px;
	 left: 5px;
}
 .position_0_1:not(.isMoving) {
	 top: 5px;
	 left: 115px;
}
 .position_0_2:not(.isMoving) {
	 top: 5px;
	 left: 225px;
}
 .position_0_3:not(.isMoving) {
	 top: 5px;
	 left: 335px;
}
 .position_1_0:not(.isMoving) {
	 top: 115px;
	 left: 5px;
}
 .position_1_1:not(.isMoving) {
	 top: 115px;
	 left: 115px;
}
 .position_1_2:not(.isMoving) {
	 top: 115px;
	 left: 225px;
}
 .position_1_3:not(.isMoving) {
	 top: 115px;
	 left: 335px;
}
 .position_2_0:not(.isMoving) {
	 top: 225px;
	 left: 5px;
}
 .position_2_1:not(.isMoving) {
	 top: 225px;
	 left: 115px;
}
 .position_2_2:not(.isMoving) {
	 top: 225px;
	 left: 225px;
}
 .position_2_3:not(.isMoving) {
	 top: 225px;
	 left: 335px;
}
 .position_3_0:not(.isMoving) {
	 top: 335px;
	 left: 5px;
}
 .position_3_1:not(.isMoving) {
	 top: 335px;
	 left: 115px;
}
 .position_3_2:not(.isMoving) {
	 top: 335px;
	 left: 225px;
}
 .position_3_3:not(.isMoving) {
	 top: 335px;
	 left: 335px;
}
 .row_from_0_to_0 {
	 top: 5px;
}
 .row_from_0_to_1 {
	 animation-duration: 0.2s;
	 animation-name: row_from_0_to_1;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_0_to_1 {
	 from {
		 top: 5px;
	}
	 to {
		 top: 115px;
	}
}
 .row_from_0_to_2 {
	 animation-duration: 0.2s;
	 animation-name: row_from_0_to_2;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_0_to_2 {
	 from {
		 top: 5px;
	}
	 to {
		 top: 225px;
	}
}
 .row_from_0_to_3 {
	 animation-duration: 0.2s;
	 animation-name: row_from_0_to_3;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_0_to_3 {
	 from {
		 top: 5px;
	}
	 to {
		 top: 335px;
	}
}
 .row_from_1_to_0 {
	 animation-duration: 0.2s;
	 animation-name: row_from_1_to_0;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_1_to_0 {
	 from {
		 top: 115px;
	}
	 to {
		 top: 5px;
	}
}
 .row_from_1_to_1 {
	 top: 115px;
}
 .row_from_1_to_2 {
	 animation-duration: 0.2s;
	 animation-name: row_from_1_to_2;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_1_to_2 {
	 from {
		 top: 115px;
	}
	 to {
		 top: 225px;
	}
}
 .row_from_1_to_3 {
	 animation-duration: 0.2s;
	 animation-name: row_from_1_to_3;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_1_to_3 {
	 from {
		 top: 115px;
	}
	 to {
		 top: 335px;
	}
}
 .row_from_2_to_0 {
	 animation-duration: 0.2s;
	 animation-name: row_from_2_to_0;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_2_to_0 {
	 from {
		 top: 225px;
	}
	 to {
		 top: 5px;
	}
}
 .row_from_2_to_1 {
	 animation-duration: 0.2s;
	 animation-name: row_from_2_to_1;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_2_to_1 {
	 from {
		 top: 225px;
	}
	 to {
		 top: 115px;
	}
}
 .row_from_2_to_2 {
	 top: 225px;
}
 .row_from_2_to_3 {
	 animation-duration: 0.2s;
	 animation-name: row_from_2_to_3;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_2_to_3 {
	 from {
		 top: 225px;
	}
	 to {
		 top: 335px;
	}
}
 .row_from_3_to_0 {
	 animation-duration: 0.2s;
	 animation-name: row_from_3_to_0;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_3_to_0 {
	 from {
		 top: 335px;
	}
	 to {
		 top: 5px;
	}
}
 .row_from_3_to_1 {
	 animation-duration: 0.2s;
	 animation-name: row_from_3_to_1;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_3_to_1 {
	 from {
		 top: 335px;
	}
	 to {
		 top: 115px;
	}
}
 .row_from_3_to_2 {
	 animation-duration: 0.2s;
	 animation-name: row_from_3_to_2;
	 animation-fill-mode: forwards;
}
 @keyframes row_from_3_to_2 {
	 from {
		 top: 335px;
	}
	 to {
		 top: 225px;
	}
}
 .row_from_3_to_3 {
	 top: 335px;
}
 .column_from_0_to_0 {
	 left: 5px;
}
 .column_from_0_to_1 {
	 animation-duration: 0.2s;
	 animation-name: column_from_0_to_1;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_0_to_1 {
	 from {
		 left: 5px;
	}
	 to {
		 left: 115px;
	}
}
 .column_from_0_to_2 {
	 animation-duration: 0.2s;
	 animation-name: column_from_0_to_2;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_0_to_2 {
	 from {
		 left: 5px;
	}
	 to {
		 left: 225px;
	}
}
 .column_from_0_to_3 {
	 animation-duration: 0.2s;
	 animation-name: column_from_0_to_3;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_0_to_3 {
	 from {
		 left: 5px;
	}
	 to {
		 left: 335px;
	}
}
 .column_from_1_to_0 {
	 animation-duration: 0.2s;
	 animation-name: column_from_1_to_0;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_1_to_0 {
	 from {
		 left: 115px;
	}
	 to {
		 left: 5px;
	}
}
 .column_from_1_to_1 {
	 left: 115px;
}
 .column_from_1_to_2 {
	 animation-duration: 0.2s;
	 animation-name: column_from_1_to_2;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_1_to_2 {
	 from {
		 left: 115px;
	}
	 to {
		 left: 225px;
	}
}
 .column_from_1_to_3 {
	 animation-duration: 0.2s;
	 animation-name: column_from_1_to_3;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_1_to_3 {
	 from {
		 left: 115px;
	}
	 to {
		 left: 335px;
	}
}
 .column_from_2_to_0 {
	 animation-duration: 0.2s;
	 animation-name: column_from_2_to_0;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_2_to_0 {
	 from {
		 left: 225px;
	}
	 to {
		 left: 5px;
	}
}
 .column_from_2_to_1 {
	 animation-duration: 0.2s;
	 animation-name: column_from_2_to_1;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_2_to_1 {
	 from {
		 left: 225px;
	}
	 to {
		 left: 115px;
	}
}
 .column_from_2_to_2 {
	 left: 225px;
}
 .column_from_2_to_3 {
	 animation-duration: 0.2s;
	 animation-name: column_from_2_to_3;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_2_to_3 {
	 from {
		 left: 225px;
	}
	 to {
		 left: 335px;
	}
}
 .column_from_3_to_0 {
	 animation-duration: 0.2s;
	 animation-name: column_from_3_to_0;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_3_to_0 {
	 from {
		 left: 335px;
	}
	 to {
		 left: 5px;
	}
}
 .column_from_3_to_1 {
	 animation-duration: 0.2s;
	 animation-name: column_from_3_to_1;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_3_to_1 {
	 from {
		 left: 335px;
	}
	 to {
		 left: 115px;
	}
}
 .column_from_3_to_2 {
	 animation-duration: 0.2s;
	 animation-name: column_from_3_to_2;
	 animation-fill-mode: forwards;
}
 @keyframes column_from_3_to_2 {
	 from {
		 left: 335px;
	}
	 to {
		 left: 225px;
	}
}
 .column_from_3_to_3 {
	 left: 335px;
}
 
 /* body {
	 display: flex;
	 background: #020024;
	 background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(87, 64, 124, 1) 47%, rgba(0, 212, 255, 1) 100%);
	 color: white;
	 flex-direction: column;
	 justify-content: center;
	 align-content: center;
	 align-items: center;
	 font-family: "Clear Sans", sans-serif;
	 font-size: 21px;
} */
 .text {
	 order: 2;
	 padding-top: 40px;
	 /* width: 440px; */
	  width: 100%;
	 font-weight: bold;
}
 .board {

	 order: 1;
	 padding: 5px;
	 background-color: #57407c;
	 border-radius: 7px;
	 outline: none;
	 position: relative;
	  max-width: 100%; 
}
 .board .cell, .tile {
	 user-select: none;
	 cursor: default;
}
 .details-box {
	 display: flex;
	 align-items: center;
	 padding: 10px;
	 justify-content: space-between;
}
 .resetButton {
	 background-color: #3d2963;
	 color: rgba(255, 255, 255, 0.5);
	 cursor: pointer;
	 font-size: 25px;
	 padding: 10px 20px;
	 border-radius: 7px;
	 transition: color 0.3s ease, background-color 0.3s ease;
}
 .resetButton:hover {
	 background-color: #d3386a;
	 color: rgba(255, 255, 255, 1);
}
 .score-header {
	 font-size: 50%;
}
 .score-box {
	text-align: center;
	 background-color: #3d2963;
	 color: rgba(255, 255, 255, 0.5);
	 min-width: 50px;
	 width: 70px;
	 padding: 5px 15px;
	 border-radius: 7px;
	 
}
 .cell {
	 width: 65px;
	 height: 65px;
	 margin: 5px;
	 line-height: 90px;
	 display: inline-block;
	 font-size: 55px;
	 font-weight: bold;
	 text-align: center;
	 vertical-align: middle;
	 border-radius: 7px;
	 font-family: "Clear Sans";
	 color: #766;
	 background-color: #3d2963;
	 overflow: hidden;
}

.tile {
	    background-size: cover;
	 width: 100%;
	 height: 100%;
	 display: block;
}


 .tile0 {
	 background-color: #dcb;
}
 .tile2 {
	 background-color: #eee;
	 background-image: url(../../../../img/2048/2.gif);
	     
}
 .tile4 {
 
	 background-color: #eec;
	 background-image: url(../../../../img/2048/4.gif);
}
 .tile8 {
 
	 color: #ffe;
	 background-image: url(../../../../img/2048/8.gif);
}
 .tile16 {
 
	 color: #ffe;
	 background-image: url(../../../../img/2048/16.gif);
}
 .tile32 {

	 color: #ffe;
	 background-image: url(../../../../img/2048/32.gif);
}
 .tile64 {
	 color: #ffe;
	 background-image: url(../../../../img/2048/64.gif);
}
 .tile128 {
	 color: #ffe;
	 background-image: url(../../../../img/2048/128.gif);
}
 .tile256 {
	 color: #ffe;
	 background-image: url(../../../../img/2048/256.gif);
	 font-size: 45px;
}
 .tile512 {
	 color: #ffe;
	 background-image: url(../../../../img/2048/512.gif);
	 font-size: 45px;
}
 .tile1024 {
	 color: #fff;
	 background-image: url(../../../../img/2048/1024.gif);
	 font-size: 35px;
}
 .tile2048 {
	 position: absolute;
	 background-size: contain;
	 left: 0;
	 right: 0;
	 top: 0;
	 bottom: 0;
	 padding: 0px;
	 color: #fff;
	 background-image: url(../../../../img/2048/2048.gif);
	 font-size: 35px;
	 background-repeat: no-repeat;
}
 .try-again {
	 z-index: 10;
	 height: 100px;
	 width: 100px;
	 background-image: url(../../../../img/2048/try-again.gif);
}
 .tile.merged {
	 display: none;
}
 .tile.merged.isMoving {
	 display: inline;
}
 .tile.new, .overlay {
	 animation-duration: 0.2s;
	 animation-name: newTile;
	 animation-fill-mode: forwards;
	 animation-delay: 0.15s;
	 transform: scale(0);
}
 @keyframes newTile {
	 from {
		 transform: scale(0);
	}
	 to {
		 transform: scale(1);
	}
}
 .overlay {
	 position: absolute;
	 top: 0px;
	 bottom: 0px;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 justify-content: center;
	 text-align: center;
	 left: 0px;
	 right: 0px;
	 font-size: 55px;
	 font-weight: bolder;
	 border-radius: 7px;
}
 .tryAgain {
	 background-color: #876;
	 color: #fff;
	 height: 40px;
	 width: 200px;
	 font-size: 20px;
	 font-weight: bold;
	 cursor: pointer;
	 border: none;
	 border-radius: 5px;
	 border: 2px solid white;
}
 .gameOver {
	 background-size: contain;
	 background-image: url(../../../../img/2048/game-over.gif);
	 position: absolute;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 z-index: 100;
	 text-align: center;
	 background-repeat: no-repeat;
}
 .overlay .message {
	 color: #666;
}
 