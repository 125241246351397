@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  /* height: 100%; */

  background-image: url("./img/bg-start.svg");
  background-color: rgb(10, 10, 10);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 100vh; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.fan-container {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.fan {
  animation: rotate 5s linear infinite;
}
.fan-fast {
  animation: rotate 1s linear infinite;
}

.fan-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fan:hover {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ant-tabs-tab-btn {
  font-size: 20px !important;
}

#ton-connect-button > div > tc-root > button > svg {
  display: none;
}
#ton-connect-button > div > tc-root > button {
  /* background: linear-gradient(to left, #004d74 0%, #0088cc 100%) !important ; */
  background: #fff !important ;
  text-align: center;
  width: 280px;
  display: flex;
  justify-content: center;
}
.go157313249 {
  width: 280px;
  display: flex !important;
  justify-content: center !important;
  background-color: #272a2f !important;
  padding: 20px !important;
}
#ton-connect-button > div > tc-root > button > div {
  color: #000 !important;
}

.btn-boot-gradient {
  background: linear-gradient(to left, #004d74 0%, #0088cc 100%);
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background 0.9s ease;
  font-size: 500;
}

.btn-boot-gradient:hover {
  background: linear-gradient(to right, #004d74 0%, #0088cc 100%) !important;
  color: white !important;
  transition: background 0.9s ease;
}

.btn-lucky-gradient {
  background: linear-gradient(to left, #17744d 0%, #676b6d 100%);
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background 0.9s ease;
  font-size: 500;
}

.btn-lucky-gradient:hover {
  background: linear-gradient(to right, #17744d 0%, #676b6d 100%) !important;
  color: white !important;
  transition: background 0.9s ease;
}
#table-customs > div > div > table > thead {
  display: none;
}
.loader {
  position: relative;
  width: 240px;
  height: 130px;
  margin-bottom: 10px;
  border: 1px solid #d3d3d3;
  padding: 15px;
  background-color: #e3e3e3;
  overflow: hidden;
}
.custom-skeleton {
  --antd-wave-shadow-color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.1);
}

.custom-skeleton-element {
  background-color: rgba(255, 255, 255, 0.2);
}


.custom-spin-button {
  display: flex;  
  justify-content: center; 
  align-items: center;  
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);  
  cursor: pointer; 
  transition: transform 0.3s ease; 
}

.custom-spin-button:active {
  transform: scale(0.95);  
}

.custom-spin-button img {
  width: 50px;  
  height: 50px;
}
.roulette-container {
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;  
}

.roulette-container img {
    width: 100%;  
    height: auto;
    display: block;  
}

.roulette-pro-regular-prize-item-wrapper{
  padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
}
.roulette-container img {
  width: 100%;
  height: 100%;
}

.custom-shadow {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.7);
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.7));
}
.prize-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.prize-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;  
}
.roulette-delimiter {
  display: none;
}


body > div:nth-child(7) > div > div.ant-modal-wrap.ant-modal-centered > div > div:nth-child(2) > div{
  background-color: transparent !important;
  background-clip: unset;
  border-radius: 0px !important;
  box-shadow: none !important;
}
body > div:nth-child(7) > div > div.ant-modal-wrap.ant-modal-centered > div{
  margin: 0px !important;
}

 
.swiper_container {
padding-bottom: 5px;
  position: relative;
}

.swiper-slide {
  position: relative;
}
.bg-header-footer{
  /* background-image: url(./img/bg-header.svg); */
  background-color: #3F3E3E;
  background-repeat: no-repeat;
  background-position: center;
  /* height: fit-content; */
  /* border-radius: 50px; */
  background-size: cover;
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);  
  }
  35% {
    transform: translateY(15px);  
  }
}

.animate-up-down {
  animation: upDown 3s ease-in-out infinite;  
}
.icon-active img { 
 color: white;
   filter: invert(100%) brightness(247%) contrast(207%);

   /* filter: brightness(0) saturate(100%) invert(51%) sepia(43%) saturate(749%) hue-rotate(140deg) brightness(100%) contrast(97%); */
}
.icon-non-active{
  filter: brightness(100%) saturate(100%) invert(100%) contrast(30%);

}


#triangle-down {
  width: 0;
  height: 0;
  border-left: 100px solid transparent;  
  border-right: 100px solid transparent; 
  border-bottom: 100px solid black;  
  margin: 40px 0 0 35px; 
   bottom: 0;
 }


.abc {
  width: 120px;  
  height: 120px;  
  background: transparent; 
  transform: rotate(45deg);
  position: absolute;
  top: -100px;  
  border-radius: 16px;
  overflow: hidden;
}
 .lotto-button-btn {
  background: linear-gradient(180deg, #00AEEF 0%, #00AEEF 100%);
  border: 3px solid #FFEBB4;
  border-radius: 50px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 40px;
  text-align: center;
  box-shadow: 0px 8px 0px #FF823D; 
  cursor: pointer;
  transition: all 0.3s ease;
}

.lotto-button-btn:hover {
  transform: translateY(-2px);  
}

.lotto-button-btn:active {
  box-shadow: none;
  transform: translateY(4px);  
} 


.light {
  position: absolute;
  width: 20px;
  height: 20px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  background-image: radial-gradient(circle, yellow, orange);
  animation: blink 1s infinite alternate;
    transform: translate(-50%, -50%);
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 0 15px rgba(255, 215, 0, 0.6);
}

@keyframes blink {
  0% {
   background-image: radial-gradient(circle, yellow, orange);
  }
  100% {
   background-image: radial-gradient(circle, white, yellow);
  }
}



.light-center{

  position: absolute;
  width: 10px;
  height: 10px;
  max-width: 10px;
  max-height: 10px;
  border-radius: 50%;
   background-image: radial-gradient(circle, rgb(8, 42, 48), rgb(58, 129, 221));
  animation: blink 1s infinite alternate;
   transform: translate(-50%, -50%);
     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 0 10px rgba(58, 129, 221, 0.5);

}
@keyframes blink-center {
  0% {
  background-image: radial-gradient(circle, rgb(12, 165, 192), rgb(225, 231, 238));
  }
  100% {
   background-image: radial-gradient(circle, rgb(110, 165, 236), rgb(200, 229, 255));
  }
}

body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div > div:nth-child(2) > div{
  background-color: transparent !important;
}
body > div:nth-child(8) > div > div.ant-modal-wrap.ant-modal-centered > div{
  margin: 0px !important;
}
.ant-modal-wrap{
  overflow: hidden !important;
}

 
 .modal-time-2048 .ant-modal-content
{ 
  background-color: transparent ;
  padding: 0px 40px;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.animate-shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
.bg-time-up-2048{
  background-image: url(./img/2048/bg-time-up.svg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
 
}
 .btn-2048-play {
background: #7BBFFE;

  /* border: 3px solid #FFEBB4; */
  border-radius: 50px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  box-shadow: 0px 5px 0px #6A60EF; 
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-2048-play:hover {
  transform: translateY(-2px);  
}

.btn-2048-play:active {
  box-shadow: none;
  transform: translateY(4px);  
} 
.custom-box-shadow {
  box-shadow: 0px 5px 0px #6A60EF; }
.gradient-wrapper {
  padding: 5px;
  background-image: linear-gradient(90deg, #ff7e5f, #feb47b, #86a8e7, #91eae4);
  border-radius: 9999px; 
  display: inline-block;
}

.custom-select .ant-select-selector {
  background-color: rgb(36, 40, 51) !important;
  border-radius: 9999px !important;
  border: none !important;
  color: white !important;
  text-align: center;
}

.custom-select .ant-select-arrow {
  color: white !important;
}
#bg-label{
  background-color: rgb(87,64,124);
 
  border-radius: 15px;
}
#bg-label > div > label:nth-child(1){
padding: 10px;
}
#bg-label > div > label.ant-segmented-item.ant-segmented-item-selected{
  background-color: rgb(61,41,99);
padding: 10px;
border-radius: 15px;
  
}
 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

 
.modal-content {
  background-color: rgb(10,11,11);  
  max-width: 400px;
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}
 
.close-modal-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: white;
}
.bg-modal-top {
 
  top: 0; 
  left: 0;  
  width: 100%; 
  align-items: center;
  overflow: hidden;  
 
 
}
.bg-modal-top-2{
   background-image: url(./img/2048/bg-modal-top.svg);
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;  
  overflow: hidden;  
   width: 100%;
   padding-top: 40px;
 
}

 
.modal-nav-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

 
.leaderboard {
  flex-grow: 1;  
  overflow-y: auto;  
  background-color: rgb(60,41,98);  
  border-radius: 20px; 
  padding: 0px 0px 10px 0px;  

  min-height: 40vh;  
  max-height: 60vh;  
}


.leaderboard-table {
  width: 100%;
  color: white;
  border-collapse: collapse;
   table-layout: fixed;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 5px 10px 5px 10px;
  
}
.leaderboard-table th {
  border-bottom: 1px solid #000;
  position: sticky;
  top: 0;
  z-index: 1;  
    background-color: rgba(32, 30, 30, 0.8);  
  backdrop-filter: blur(5px); 
 
  padding: 20px;
 }
.leaderboard-table th {
 border-bottom:1px solid #000;
}
.scrollable-tbody {
  display: block;  
  max-height: 300px; 
  overflow-y: auto;  

}

.scrollable-tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;  
}
 