.prevent-select {
  webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.ant-modal {
  z-index: 1040 !important;
}
.ant-modal-wrap {
  z-index: 1040 !important;
}
.go37342527.go1901766449 {
  z-index: 1050 !important;
}
.go823957079.s-enter-active.s-enter-to {
  z-index: 1050 !important;
}
.go816652413.go1522830083.s-enter-active.s-enter-to {
  z-index: 1055 !important;
}
body > div:nth-child(6) > tc-root {
  z-index: 1055 !important;
}
.menu .router-link-exact-active {
  background: #1c1f24;
  color: #fff;
}
.bg-header {
  background-color: #272a2f;
  border-top: 2px solid #fdbd22;
}
.border-custom-top {
  border-top: 2px solid #fdbd22;
}
.border-custom-top-first {
  border-top: 2px solid #33bbf7;
}
.border-custom-footer {
  border-bottom: 1px solid #fdbd22;
}
.border-custom-home {
  height: 100px;

  justify-content: space-between;
  border-top: 2px solid #fdbd22;
  align-items: center;
}
.bg-modal-boost {
  background: #000;
  position: fixed;
  top: 0;

  transition: top 0.3s ease-out;
}
.bg-footer-modal {
  /* position: absolute;
  bottom: 5%;
  right: 0;
  left: 0; */
  margin-top: 10vh !important;
}
.ant-modal-wrap > div {
  top: 0px !important;
}
.ant-modal-wrap > .modal-claim {
  top: 30% !important;
  /* background-color: #212121; */
  background-color: #000;
  background-image: url("../../img/bgflower.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.modal-claim .ant-modal-content {
  background-color: #212121;
}
.ant-modal-wrap > .modal-learn {
  top: 20% !important;
  /* background-image: url("../../img/bg-learn.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%; */
  background-color: #000;
}
/* body > div > div > div.ant-modal-wrap > div > div > div { */
.modal-learn .ant-modal-content {
  /* background-image: url("../../img/bg-learn.svg") !important; */
  background-color: transparent !important;
  z-index: 10;
  background-image: url("../../img/bg-learn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}
body
  > div
  > div
  > div.ant-modal-wrap
  > div
  > div
  > div
  > button
  > span
  > span
  > svg {
  color: #fff;
}
.border-custom-modal {
  border-top: 2px solid #f3ba2f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 15px #fab82299;
}

.overflow-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.overflow-scroll {
  scrollbar-width: none;
}

.bg-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.modal-claim .ant-modal-content {
  background-image: url("../../img/bgflower.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.gif-img {
  width: 70% !important;
}
#ton-connect-button > div > tc-root > button {
  height: 50px !important;
  width: 100%;
}
#ton-connect-button > div,
#ton-connect-button > div > tc-root {
  width: 100%;
}
.go3572451881 {
  margin: 0 auto;
}
.bg-modal-wait {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10 !important;
}
.bg-modal-boost .ant-modal-close {
  z-index: 0 !important;
}
body > div > div > div.ant-modal-wrap > div > div > div > button {
  z-index: 1 !important;
}
.popup-start .ant-modal-content {
  background-color: transparent;
  padding: 0 !important;
}
.popup-start .ant-modal-close {
  display: none !important;
}
.bg-cup {
  /* background-image: url("../../img/bg-cup.svg"); */
}
.bg-table-cup {
  background-color: rgba(255, 255, 255, 0.05);
}
.popup-leader .ant-modal-content {
  background-image: url("../../img/bg-cup.svg");
  /* background-color: rgba(255, 255, 255, 0.05); */
  background-color: #000 !important;
  color: #fff;
  padding: 0 !important;
}
.popup-home-first {
  background-image: url("../../img/bgPre-first.svg");
  background-color: #000;
  /* background-size: cover; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 378px;
  width: 100%;
  height: 400px;
}
.popup-home-last {
  background-image: url("../../img/bgPre-last.svg");
  background-color: #000;
  /* background-size: cover; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 378px;
  width: 100%;
  height: 208px;
}
/* .popup-home-first {
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #98f9ff, #eabfff);
  border-radius: 1rem;
  overflow: hidden;
} */
.icon-arrow-left {
  border: 1px solid #212121;
  border-radius: 4px;
}
.item-lucky {
  border: 1px solid #212121;
}
.ant-modal-wrap > .popup-lucky {

  position: fixed;
  right: 0;
  left: 0;
  top: auto !important;
  bottom: 0 !important;
  /* background-color: #212121; */
  background-color: #212121;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100% !important;
  margin: 0 !important;
  max-width: 100% !important;
  padding: 0 !important;
}
.popup-lucky .ant-modal-content {
  background-color: #212121;
  color: #fff;

}
.border-cancel {
  border: 1px solid #fff;
}
.ant-modal-wrap > .popup-lottery {
 
 
  top: 30% !important;
 
  /* background-color: #212121; */
  background-color: #212121;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100% !important;
  margin: 0  !important;
  max-width: 100% !important;
  padding:  0 !important;
}
.popup-lottery .ant-modal-content {
  background-color: #212121;
  color: #fff;
 
}